.logo {
    width: 100%;
    max-width: 24px;
    height: 24px;
    margin: 16px 24px 16px 0;
}

.header {
    position: 'fixed';
    width: '100%';
}